<template>
  <div class="container" id="legalEntitiesOverview">
    <h1 :class="this.isActive ? '' : 'inactive-cell'">
      {{ this.farm['title' + $route.params.upLang] }}
    </h1>
    <v-tabs v-model="activeTab" color="primary">
      <v-tab id="overview" value="overview"><span v-html="$t('masterdata_farms_overview')" /></v-tab>
      <v-tab id="addresses" value="addresses" v-if="$getBeta()"><span v-html="$t('masterdata_farms_contacts')" /></v-tab>
      <v-tab id="relations" value="relations" v-if="!readonly"><span v-html="$t('masterdata_farms_relations')" /></v-tab>
    </v-tabs>
    <v-window v-model="activeTab">
      <v-window-item value="overview">
        <farms-overview v-if="childDataLoaded" :farmData="this.farm"></farms-overview>
      </v-window-item>
      <v-window-item value="addresses" v-if="$getBeta()">
        <farms-contacts></farms-contacts>
      </v-window-item>
      <v-window-item value="relations" v-if="!readonly">
        <farms-relations></farms-relations>
      </v-window-item>
    </v-window>
  </div>
</template>

<script lang="ts">
import farmsOverview from './overview'
import FarmsContacts from './contacts'
import FarmsRelations from './relations'
import { privileges } from '@/services/privileges'
import { apiURL } from '@/main'
import moment from 'moment'
import { defineComponent } from 'vue'

let today = new Date()
let yesterday = moment(today).subtract(1, 'day').toISOString()

export default defineComponent({
  name: 'farmsEdit',
  components: { FarmsRelations, FarmsContacts, farmsOverview },
  props: { id: { Number }, tab: { String } },
  data() {
    return {
      apiURL: apiURL + '/farms/',
      formPart: {
        id: null
      },
      farm: {},
      childDataLoaded: false,
      isActive: true
    }
  },
  computed: {
    readonly() {
      return !privileges.has({ path: '/farms', permission: 'write' })
    },
    activeTab: {
      get(): String {
        return this.tab
      },
      set(tabChoosen: string): void {
        console.log('tabChoosen', tabChoosen)
        this.$router
          .push({
            name: 'masterdata_farms_edit',
            params: { id: parseInt(this.id), tab: tabChoosen }
          })
          .catch((e) => {
            /* nav duplicated */
          })
      }
    }
  },
  methods: {
    async loadFarm(farmId) {
      try {
        const response = this.axios.get((this.apiURL += farmId), {
          headers: { Accept: 'application/json' }
        })
        this.farm = (await response).data
        this.farm.localEntityType = this.$t('masterdata_farms_farm')
        this.childDataLoaded = await response
        if ((await response).data.validUntil <= yesterday) {
          this.isActive = false
        }
      } catch (e) {
        showError(e)
      }
    }
  },
  mounted() {
    this.$nextTick(async () => {
      await this.loadFarm(this.$route.params.id)
    })
  }
})
</script>
