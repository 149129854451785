<template>
  <v-container>
    <v-col xs="2" md="3" xl="4" v-if="legalEntityDefaultContact">
      <!-- Addressvorschau -->
      <address-preview v-bind:address="legalEntityDefaultContact"></address-preview>
    </v-col>
    <v-col>
      <h1>TBD - Adresse der Lokalen Einheit</h1>
    </v-col>
    <v-col>
      <v-btn color="secondary" className="button-margin" @click="navigateBackToSearch">
        <span v-html="$t('farms_back_button')" />
      </v-btn>
    </v-col>
  </v-container>
</template>

<script lang="ts">
import { apiURL } from '../../main'
import { showError } from '../../services/axios'
import addressPreview from '../masterdata/addressPreview'

import { defineComponent } from 'vue'
export default defineComponent({
  name: 'farmsContacts',
  components: { addressPreview },
  data() {
    return {
      legalEntityDefaultContact: []
    }
  },
  methods: {
    async load(id) {
      try {
        const response = await this.axios.get(apiURL + '/legalEntities/' + id + '' + '/defaultAddress', { headers: { Accept: 'application/json' } })
        this.legalEntityDefaultContact = await response.data
      } catch (e) {
        this.legalEntityDefaultContact = []
        showError(e)
      }
    },
    navigateBackToSearch() {
      this.$router
        .push({
          name: 'masterdata_farms_search'
        })
        .catch((e) => {
          /* duplicated route */
        })
    }
  },
  mounted() {
    this.$nextTick(async () => {
      try {
        const response = await this.axios.get(apiURL + '/farms/' + this.$route.params.id + '/managements', { headers: { Accept: 'application/json' } })
        const items = await response.data

        if (items[0]?.legalEntityId) {
          await this.load(items[0]?.legalEntityId)
        }
      } catch (e) {
        showError(e)
      }
    })
  }
})
</script>
