<template>
  <v-container>
    <v-form @submit="onSubmit">
      <v-row>
        <v-col cols="12" sm="6" md="6" lg="4">
          <h2 v-html="$t('masterdata_farms_overview_general_data')"></h2>
          <!-- Lokale Einheit: Produzent -->
          <v-text-field
            :disabled="readonly"
            id="localEntityType"
            v-model="farm.localEntityType"
            :label="$t('masterdata_farms_local_entity')"
            type="text"
            readonly
          />
          <!-- Betriebsform -->
          <v-select
            :disabled="readonly"
            v-model="farm.farmType"
            :label="$t('masterdata_farms_dairy_type_id')"
            :items="farmTypes"
            :item-title="this.$getLangKey()"
            return-object
          />
          <!-- BUR -->
          <v-text-field :disabled="readonly" id="berNo" v-model="farm.berNo" :label="$t('masterdata_farms_berno')" type="text" />
          <!-- EGID -->
          <v-text-field :disabled="readonly" id="egid" v-model="farm.egid" :label="$t('masterdata_farms_egid')" type="number" />
          <!-- AGIS_ID_Betrieb -->
          <v-text-field :disabled="readonly" id="agisid" v-model="farm.agisId" :label="$t('masterdata_farms_agisId')" type="number" />
          <!-- Zulagen -->
          <v-switch
            :disabled="
              readonly ||
              !$privileges.has({
                path: '/farms/hasSubsidies',
                permission: 'write'
              })
            "
            v-model="farm.hasSubsidies"
            id="hasSubsidies"
            :label="$t('masterdata_farms_has_subsidies')"
            color="info"
          ></v-switch>
          <!-- DBV -->
          <v-switch :disabled="readonly" v-model="farm.agisClearing" id="agisClearing" :label="$t('masterdata_farms_agis_clearing')" color="info"></v-switch>
          <!-- Inaktiv per - valid until-->
          <DbmDatePicker :readonly="readonly" v-model="farm.validUntil" :dateLabel="$t('masterdata_inactive_by')" :clearable="true" />
        </v-col>

        <v-col cols="12" sm="6" md="6" lg="4">
          <h2 v-html="$t('masterdata_farms_processing_form_data')"></h2>

          <!-- Kant. Nr.-->
          <v-text-field :disabled="readonly" id="cantonNo" v-model="farm.cantonalId" :label="$t('masterdata_farms_canton_no')" type="text" />
          <!-- TVD-Nr..-->
          <v-text-field :disabled="readonly" id="tvdNo" v-model="farm.tvdId" :label="$t('masterdata_farms_tvd_no')" type="text" />
          <!-- Produktionsgebiet -->
          <v-select
            :disabled="readonly"
            id="productionArea"
            v-model="farm.productionArea"
            :label="$t('masterdata_farms_production_area')"
            :items="productionAreas"
            :item-title="this.$getLangKey()"
            return-object
          />
          <!-- Produktionszone -->
          <v-select
            :disabled="readonly"
            id="productionZone"
            v-model="farm.productionZone"
            :label="$t('masterdata_farms_production_zone')"
            :items="productionZones"
            :item-title="this.$getLangKey()"
            return-object
          />
          <!-- ÖLN - ecologicalPerformanceRecord-->
          <v-switch
            :disabled="readonly"
            id="epr"
            v-model="farm.ecologicalPerformanceRecord"
            :label="$t('masterdata_farms_ecologicalPerformanceRecord')"
            color="info"
          />
          <!-- Voraussetzung Sömmerung -->
          <v-switch :disabled="readonly" id="alpinePasture" v-model="farm.alpinePasture" :label="$t('masterdata_farms_alpinePasture')" color="info" />
          <!-- Bio -->
          <v-switch :disabled="readonly" id="organic" class="last-switch" v-model="farm.organic" :label="$t('masterdata_farms_organic')" color="info" />
          <!-- Anzahl Kühe-->
          <v-text-field :disabled="readonly" id="noCows" v-model="farm.dairyCows" :label="$t('masterdata_farms_no_cows')" type="number" />
          <!-- Fläche [a]-->
          <v-text-field :disabled="readonly" id="squaremeters" v-model="farm.area" :label="$t('masterdata_farms_squaremeters')" type="number" />
          <!-- Höhe ü. M. [m]-->
          <v-text-field :disabled="readonly" id="zcoord" v-model="farm.zcoord" :label="$t('masterdata_farms_zcoord')" type="number" />
          <!-- X_Koordinate-->
          <v-text-field :disabled="readonly" id="xCoordinates" v-model="farm.xcoord" :label="$t('masterdata_farms_x_coordinates')" type="number" />
          <!-- Y_Koordinate-->
          <v-text-field :disabled="readonly" id="yCoordinates" v-model="farm.ycoord" :label="$t('masterdata_farms_y_coordinates')" type="number" />
        </v-col>
        <v-col cols="12" v-if="$getBeta()" class="beta">
          <h2 v-html="$t('masterdata_farms_hierarchy')"></h2>
          <hierarchy type="Farm" :id="$route.params.id"></hierarchy>
        </v-col>
      </v-row>
    </v-form>

    <v-row>
      <v-col class="item card-spacing" sm="12" md="6" lg="6">
        <rdhComponent
          :show="$privileges.has({ path: '/masterData', permission: 'showRemarksDocumentsHistory' })"
          :remarks_show="true"
          :remarks_tag="'farms_' + $route.params.id"
          :documents_show="true"
          :documents_tag="'farms_' + $route.params.id"
          :history_show="true"
          :history_url="'/farms/' + $route.params.id"
          ref="rdh"
        ></rdhComponent>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" sm="12" md="12" lg="12">
        <v-btn id="btn-edit-overview-save" color="primary" class="button-margin button-top-margin shadow-none" @click="onSubmit()" v-if="!readonly"
          ><span v-html="$t('masterdata_farms_save')"
        /></v-btn>
        <v-btn
          id="btn-edit-overview-save-and-close"
          color="secondary"
          class="button-margin button-top-margin shadow-none"
          @click="submitAndClose()"
          v-if="!readonly"
          ><span v-html="$t('masterdata_farms_save_and_close')"
        /></v-btn>
        <v-btn color="secondary" className="button-margin" @click="navigateBackToSearch">
          <span v-html="$t('farms_back_button')" />
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="ts">
import { apiURL, emitter } from '@/main'

import type { components } from '@/lib/api/v1.d.ts'
type Farm = components['schemas']['Farm']
import { showError } from '@/services/axios'
import DbmDatePicker from '@/components/dbmDatePicker.vue'
import { privileges } from '@/services/privileges'
import rdhComponent from '@/components/rdh/rdhComponent.vue'
import Hierarchy from '@/components/hierarchy.vue'
import { useFarmTypesStore } from '@/store/enums/FarmTypesStore'
import { useProductionAreasStore } from '@/store/enums/ProductionAreasStore'
import { useProductionZonesStore } from '@/store/enums/ProductionZonesStore'

import { defineComponent } from 'vue'
export default defineComponent({
  name: 'farmsOverview',
  props: { farmData: { type: Object, required: true } },
  components: {
    DbmDatePicker: DbmDatePicker,
    rdhComponent: rdhComponent,
    hierarchy: Hierarchy
  },
  data() {
    return {
      farm: { ...this.farmData } as Farm
    }
  },
  computed: {
    readonly() {
      return !privileges.has({ path: '/farms', permission: 'write' })
    },
    farmTypes() {
      return useFarmTypesStore().farmTypes
    },
    productionAreas() {
      return useProductionAreasStore().productionAreas
    },
    productionZones() {
      return useProductionZonesStore().productionZones
    }
  },
  methods: {
    async onSubmit() {
      delete this.farm.formattedAddress
      delete this.farm.localEntityType
      try {
        const result = await this.axios.patch(
          apiURL + '/farms/' + this.$route.params.id,
          {
            ...this.farm,
            validUntil: this.$formatDateTime(this.farm.validUntil)
          },
          { headers: { 'Content-Type': 'application/json' } }
        )
        if (await result) {
          emitter.emit('toastSuccess', this.$t('toast_save_success_message'))
          emitter.emit('loadHistory')
        }
      } catch (e) {
        showError(e)
      }
    },
    submitAndClose() {
      this.onSubmit()
      this.back()
    },
    back() {
      this.$router.push({
        name: 'masterdata_search'
      })
    },
    navigateBackToSearch() {
      this.$router
        .push({
          name: 'masterdata_farms_search'
        })
        .catch((e) => {
          /* duplicated route */
        })
    }
  },
  beforeMount() {
    useFarmTypesStore().fetchFarmTypes()
  }
})
</script>
