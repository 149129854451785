<template>
  <v-container>
    <v-row>
      <v-col cols="12" xl="12" class="bottom-margin">
        <div>
          <h2 v-html="$t('masterdata_farms_bewirtschaftet_von_title')"></h2>
          <v-row>
            <v-col>
              <div v-if="bewirtschafter && bewirtschafter.length > 0">
                <!-- existing relations -->
                <v-data-table-server
                  density="compact"
                  :headers="fields"
                  :items="bewirtschafter"
                  :items-length="bewirtschafterTotalElements"
                  :loading="loadingRelation1"
                  :hide-default-footer="true"
                  disable-pagination
                  class="custom-simple-table"
                >
                  <template v-slot:[`item.validFrom`]="{ item }">
                    <dbmDatePicker
                      class="nomessage"
                      v-model="item.validFrom"
                      :required="true"
                      @change="
                        (emittedDate) => {
                          item.validFrom = emittedDate
                          patchRow(item)
                        }
                      "
                    ></dbmDatePicker>
                  </template>
                  <template v-slot:[`item.validUntil`]="{ item }">
                    <dbmDatePicker
                      class="nomessage"
                      v-model="item.validUntil"
                      :clearable="true"
                      @change="
                        (emittedDate) => {
                          item.validUntil = emittedDate
                          patchRow(item)
                        }
                      "
                    ></dbmDatePicker>
                  </template>
                  <template
                    v-slot:[`item.delete`]="{ item }"
                    v-if="
                      $privileges.has({
                        path: '/managements',
                        permission: 'delete'
                      })
                    "
                  >
                    <v-btn :loading="deleteManagementLoading" size="sm" @click="deleteManagement(item)">
                      <v-icon dbmblueprimary>mdi-delete</v-icon>
                    </v-btn>
                  </template>
                </v-data-table-server>
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" xl="12">
              <v-btn id="addManagement-submit-btn" color="primary" @click="addManagement()"><span v-html="$t('masterdata_farms_add')" /></v-btn>
            </v-col>
          </v-row>
        </div>
      </v-col>

      <v-col cols="12" xl="12" class="bottom-margin">
        <display-farm-affiliations :id="dairyId" />
      </v-col>

      <!--      Freigaben-->
      <v-col cols="12" xl="12" class="bottom-margin beta" v-if="$getBeta()">
        <div>
          <h2 v-html="$t('masterdata_farms_freigaben_title')"></h2>
          <v-data-table-server
            density="compact"
            :headers="accessesfields"
            :items="accesses"
            :loading="loading"
            :hide-default-footer="true"
            disable-pagination
            class="custom-simple-table"
            :items-length="accessesTotalElements"
          >
            <template v-slot:[`item.validFrom`]="{ item }">
              <div v-if="item.validFrom">
                {{ format(item.validFrom, 'dd.MM.yyyy') }}
              </div>
            </template>
            <template v-slot:[`item.validUntil`]="{ item }">
              <div v-if="item.validUntil">
                {{ format(item.validUntil, 'dd.MM.yyyy') }}
              </div>
            </template>
            <template v-slot:[`item.status`]="{ item }">
              <div>{{ showStatus(item.status) }}</div>
            </template>
            <template v-slot:[`item.delete`]="{ item }" v-if="$privileges.has({ path: '/farmAccesses', permission: 'delete' })">
              <v-btn :loading="deleteFarmAccessLoading" size="sm" @click="deleteFarmAccess(item)">
                <v-icon dbmblueprimary>mdi-delete</v-icon>
              </v-btn>
            </template>
          </v-data-table-server>
          <v-btn id="add-farm-access-submit-btn" color="primary" @click="addFarmAccesses()"><span v-html="$t('masterdata_farms_add')" /></v-btn>
        </div>
      </v-col>
    </v-row>

    <!-- Zurück -->
    <v-row>
      <v-col>
        <v-btn id="btn-edit-cancel" color="secondary" class="button-margin button-top-margin" @click="navigateBackToSearch()"
          ><span v-html="$t('farms_back_button')"
        /></v-btn>
      </v-col>
    </v-row>

    <!-- addManagement Search/Select LegalEnity -->
    <v-dialog :retain-focus="false" v-model="addManagementPicker.showDialog" max-width="75%" id="masterdata-farms-dialog">
      <v-form ref="createManagement" @submit.prevent="submitNewManagement()">
        <v-card>
          <v-card-title><span v-html="$t('masterdata_farms_add_new_relation')" /></v-card-title>
          <v-card-text>
            <v-layout row justify-center>
              <v-overlay v-model="loading" persistent fullscreen id="loading-dialog" content-class="loading-dialog">
                <v-container fill-height>
                  <v-layout row justify-center align-center>
                    <v-progress-circular indeterminate color="secondary"></v-progress-circular>
                  </v-layout>
                </v-container>
              </v-overlay>
            </v-layout>
            <v-row class="row search spacer-md">
              <v-col cols="6" md="6" xl="4">
                <!-- Legal Entity Picker -->
                <dbm-extended-legal-entity-picker
                  v-model="addManagementPicker.selected"
                  :label="$t('masterdata_farms_relation_picker')"
                  name="masterdata_farms_relation_picker"
                  :rules="[$rules.required]"
                  :cards="['searchLocalUnit', 'searchFarm']"
                />
                <!-- ValidFrom -->
                <DbmDatePicker v-model="addManagementPicker.validFrom" :dateLabel="$t('masterdata_farms_valid_from')" :clearable="true" />
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-btn id="submit-btn" class="m-2" color="primary" type="submit"><span v-html="$t('masterdata_farms_add_button')" /></v-btn>
            <v-btn id="close-btn" class="m-2" color="secondary" @click="closeDialog()" type="submit"><span v-html="$t('close')" /></v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>

    <!-- Pick LegalEntity for temporary farm access -->
    <v-dialog :retain-focus="false" v-model="this.selectedForFarmAccess.showDialog" max-width="75%" id="farm-accesses-dialog">
      <v-form ref="createfarmaccess" @submit.prevent="submitForFarmAccesses()">
        <v-card>
          <v-card-title><span v-html="$t('masterdata_farms_add_access')" /></v-card-title>

          <v-card-text>
            <v-layout row justify-center>
              <v-overlay v-model="loading" persistent fullscreen id="loading-farm-access-dialog" content-class="loading-dialog">
                <v-container fill-height>
                  <v-layout row justify-center align-center>
                    <v-progress-circular indeterminate color="secondary"></v-progress-circular>
                  </v-layout>
                </v-container>
              </v-overlay>
            </v-layout>
            <v-row class="row search spacer-md">
              <v-col cols="6" md="6" xl="4">
                <!-- Person Picker-->
                <dbm-extended-legal-entity-picker
                  v-model="selectedForFarmAccess.legalEntityId"
                  :label="$t('masterdata_farm_access_relation_picker')"
                  name="masterdata_farm_access_relation_picker"
                  :rules="[$rules.required]"
                  :cards="['searchApplicationUser', 'searchContact', 'searchLegalEntity']"
                />
                <!-- ValidFrom -->
                <DbmDatePicker :required="true" v-model="selectedForFarmAccess.validFrom" :dateLabel="$t('farm_data_free_access_table_from')" />
                <!-- ValidUntil -->
                <DbmDatePicker v-model="selectedForFarmAccess.validUntil" :dateLabel="$t('farm_data_free_access_table_until')" :clearable="true" />
                <!--    Tierart-->
                <v-select
                  id="animalType_id"
                  :rules="[rules.required]"
                  v-model="selectedForFarmAccess.animalTypeId"
                  :label="$t('search_contract_animal_type')"
                  :items="animalTypes"
                  :item-title="this.$getLangKey()"
                  item-value="id"
                  clearable
                  @keyup.enter="search"
                />
                <!--    Zugriffberechtigung-->
                <v-select
                  id="farm_access_type"
                  :rules="[rules.required]"
                  item-value="name"
                  v-model="selectedForFarmAccess.farmAccessType"
                  :label="$t('farm_data_free_access_farm_access_type')"
                  :items="farmAccessTypes"
                  :item-title="this.$getLangKey()"
                  clearable
                  @keyup.enter="search"
                />
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-btn id="farm-accesses-submit-btn" class="m-2" color="primary" @click="submitForFarmAccesses"
              ><span v-html="$t('masterdata_farms_add_button')"
            /></v-btn>
            <v-btn id="farm-accesses-close-btn" class="m-2" color="secondary" @click="closeAccessDialog()"><span v-html="$t('close')" /></v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </v-container>
</template>

<script lang="ts">
import { apiURL, emitter } from '@/main'
import { showError } from '@/services/axios'
import DisplayFarmAffiliations from './displayFarmAffiliations.vue'
import { useFarmAccessStatusesStore } from '@/store/enums/FarmAccessStatusesStore'
import DbmDatePicker from '@/components/dbmDatePicker.vue'
import { mapGetters, mapActions } from 'vuex'
import { CustomFormatter } from '@/views/translations/CustomFormatter'
import { DTSHeader } from '@/services/BackendService'
import { format } from 'date-fns'
import { useAnimalTypesStore } from '@/store/enums/AnimalTypesStore'

import { useFarmAccessTypesStore } from '@/store/enums/FarmAccessTypesStore'

import { defineComponent } from 'vue'
import DbmExtendedLegalEntityPicker from '@/components/pickers/dbmExtendedLegalEntityPicker.vue'
export default defineComponent({
  name: 'farmsRelations',
  data() {
    return {
      rules: {
        required: (value) => !!value || this.$t('form_field_required')
      },
      dairyId: parseInt(this.$route.params.id),
      loadingRelation1: false,
      loading: false,
      dialogsettingsForFarmAccess: { loading: false, totalElements: 0 },
      bewirtschafter: [] as any[] | undefined,
      bewirtschafterTotalElements: 0,
      accesses: [],
      accessesTotalElements: 0,
      itemForFarmAccess: [],
      addManagementPicker: {
        items: [],
        showDialog: false,
        loading: false,
        totalElements: 0,
        selected: null,
        validFrom: null
      },
      selectedForFarmAccess: {
        legalEntityId: null,
        validFrom: '',
        validUntil: '',
        farmAccessType: '',
        animalTypeId: null,
        showDialog: false
      },
      deleteFarmAccessLoading: false,
      deleteManagementLoading: false,
      fields: <DTSHeader[]>[
        {
          title: this.$t('masterdata_farms_legalEntityAddressName1'),
          key: 'legalEntityAddressName1',
          sortable: false
        },
        {
          title: this.$t('masterdata_farms_legalEntityAddressName2'),
          key: 'legalEntityAddressName2',
          sortable: false
        },
        {
          title: this.$t('masterdata_farms_agisId'),
          key: 'agis',
          sortable: false,
          align: 'left'
        },
        {
          title: this.$t('affiliations_valid_from'),
          key: 'validFrom',
          sortable: false,
          width: '25%'
        },
        {
          title: this.$t('affiliations_valid_until'),
          key: 'validUntil',
          sortable: false,
          width: '25%'
        },
        // Delete icon
        {
          title: '',
          key: 'delete',
          sortable: false
        }
      ],
      accessesfields: <DTSHeader[]>[
        //Name/Firma
        {
          title: this.$t('farm_data_free_access_table_surname'),
          key: 'legalEntityOwner.name1',
          sortable: false
        },
        // Vorname/Zusatz
        {
          title: this.$t('farm_data_free_access_table_name'),
          key: 'legalEntityOwner.name2',
          sortable: false
        },
        //UID
        {
          title: this.$t('farm_data_free_access_table_uid'),
          key: 'legalEntityOwner.uid',
          sortable: false
        },
        //Tierart
        {
          title: this.$t('farm_data_free_access_table_animal_type'),
          key: 'animalType' + this.$getUpLangKey(),
          sortable: false
        },
        //Typ
        {
          title: this.$t('farm_data_free_access_table_type'),
          key: 'farmAccessType' + this.$getUpLangKey(),
          sortable: false
        },
        // Status
        {
          title: this.$t('farm_data_free_access_table_status'),
          key: 'status',
          sortable: false
        },
        //Von
        {
          title: this.$t('farm_data_free_access_table_from'),
          key: 'validFrom',
          sortable: false
        },
        //Bis
        {
          title: this.$t('farm_data_free_access_table_until'),
          key: 'validUntil',
          sortable: false
        },
        // Delete icon
        {
          title: '',
          key: 'delete',
          sortable: false
        }
      ],
      managementId: 0,
      accessDialog: false
    }
  },
  computed: {
    farmAccessTypes() {
      return useFarmAccessTypesStore().farmAccessTypes
    },
    farmAccessStatuses() {
      return useFarmAccessStatusesStore().farmAccessStatuses
    },
    animalTypes() {
      return useAnimalTypesStore().AnimalTypes
    }
  },
  components: {
    DbmExtendedLegalEntityPicker,
    DisplayFarmAffiliations,
    DbmDatePicker
  },
  methods: {
    async deleteManagement(item) {
      const formatter = new CustomFormatter()
      const formattedMessage = formatter.interpolate(this.$t('masterdata_farms_managements_confirm_delete'), [item.legalEntityAddressName1])
      if (!confirm(formattedMessage)) return

      this.deleteManagementLoading = true
      try {
        const result = await this.axios.delete(
          apiURL + '/managements/' + item.managementId,
          { params: { id: item.managementId } },
          { headers: { 'Content-Type': 'application/json' } }
        )
        if (await result) {
          this.bewirtschafter = await this.loadRelations(this.$route.params.id)
        }
      } catch (e) {
        showError(e)
      } finally {
        this.deleteManagementLoading = false
      }
    },
    async deleteFarmAccess(item) {
      this.deleteFarmAccessLoading = true
      try {
        const result = await this.axios.delete(
          apiURL + '/farmAccesses/' + item.id,
          { params: { id: item.id } },
          { headers: { 'Content-Type': 'application/json' } }
        )
        if (await result) {
          this.loadAccesses()
        }
      } catch (e) {
        showError(e)
      } finally {
        this.deleteFarmAccessLoading = false
      }
    },
    validateInput() {
      if (this.selectedForFarmAccess.animalTypeId && this.selectedForFarmAccess[0].legalEntityId && this.selectedForFarmAccess.validFrom) {
        return false
      } else return true
    },
    showStatus(status) {
      const translatedStatus = this.farmAccessStatuses.filter(function (element) {
        return element.name == status
      })
      return translatedStatus[0][this.$getLangKey()]
    },
    async loadRelations(itemId) {
      this.loading = true
      try {
        const response = await this.axios.get(apiURL + '/farms/' + itemId + '/managements', { headers: { Accept: 'application/json' } })
        const items = await response.data
        return Array.isArray(items) ? items : []
      } catch (e) {
        showError(e)
      } finally {
        this.loading = false
      }
    },

    async loadAccesses() {
      this.loading = true
      try {
        const response = await this.axios.get(apiURL + '/farms/' + this.$route.params.id + '/farmAccesses', { headers: { Accept: 'application/json' } })
        this.accesses = await response.data.content
      } catch (e) {
        showError(e)
      } finally {
        this.loading = false
      }
    },
    async patchRow(item) {
      this.loadingRelation1 = true
      try {
        const result = this.axios.patch(`/api/managements/${item.managementId}`, {
          validFrom: format(item.validFrom, 'yyyy-MM-dd'),
          validUntil: item.validUntil ? format(item.validUntil, 'yyyy-MM-dd') : null
        })
        if (await result) {
          emitter.emit('toastSuccess', this.$t('toast_save_success_message'))
          return result.data
        }
      } catch (e) {
        item.validFrom = ''
        item.validUntil = ''
        showError(e)
      } finally {
        this.loadingRelation1 = false
      }
    },
    async submitNewManagement() {
      this.loading = true
      try {
        const result = await this.axios.post(
          apiURL + '/managements/',
          {
            validFrom: format(this.addManagementPicker.validFrom, 'yyyy-MM-dd'),
            legalEntity: { id: this.addManagementPicker.selected },
            localUnit: { id: this.$route.params.id } // of the farm
          },
          { headers: { 'Content-Type': 'application/json' } }
        )
        if (await result) {
          this.bewirtschafter = await this.loadRelations(this.$route.params.id)
          this.addManagementPicker.showDialog = false
        }
      } catch (e) {
        showError(e)
      } finally {
        this.loading = false
      }
    },
    async submitForFarmAccesses() {
      if (!(await this.$refs.createfarmaccess.validate()).valid) {
        return false
      }
      this.loading = true
      try {
        const result = await this.axios.post(
          apiURL + '/farmAccesses/',
          {
            validFrom: this.selectedForFarmAccess.validFrom,
            validUntil: this.selectedForFarmAccess.validUntil,
            domain: { id: this.selectedForFarmAccess.legalEntityId },
            farm: { id: this.$route.params.id },
            animalType: { id: this.selectedForFarmAccess.animalTypeId },
            type: this.selectedForFarmAccess.farmAccessType,
            status: 'REQUESTED'
          },
          { headers: { 'Content-Type': 'application/json' } }
        )
        if (await result) {
          this.selectedForFarmAccess.showDialog = false
          this.loadAccesses()
          emitter.emit('toastSuccess', this.$t('toast_save_success_message'))
        }
      } catch (e) {
        showError(e)
      } finally {
        this.loading = false
      }
    },
    addManagement() {
      this.addManagementPicker.showDialog = true
      this.loading = false
    },
    addFarmAccesses() {
      this.selectedForFarmAccess.showDialog = true
      this.loading = false
    },
    closeDialog() {
      this.addManagementPicker.selected = null
      this.addManagementPicker.showDialog = false
    },
    closeAccessDialog() {
      this.selectedForFarmAccess.showDialog = false
    },

    navigateBackToSearch() {
      this.$router
        .push({
          name: 'masterdata_farms_search'
        })
        .catch((e) => {
          /* duplicated route */
        })
    }
  },
  beforeMount() {
    useAnimalTypesStore().fetchAnimalTypes()
    useFarmAccessStatusesStore().fetchFarmAccessStatuses()
    useFarmAccessTypesStore().fetchFarmAccessTypes()
  },
  async mounted() {
    this.bewirtschafter = await this.loadRelations(this.$route.params.id)
    this.loadAccesses()
  }
})
</script>

<style lang="scss" scoped>
@import '@/styles/colors.scss';
.add-bewirtschafter {
  margin-bottom: 0;
  color: $dbm-primary-blue;
}

.add-bewirtschafter:hover {
  cursor: pointer;
}
</style>
