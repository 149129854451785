import { defineStore } from 'pinia'
export const useProductionAreasStore = defineStore('productionAreasStore', {
  state: () => ({
    productionAreas: [
      {
        id: 1,
        nameDe: 'Talgebiet',
        nameFr: 'Région de plaine',
        nameIt: 'Regione di pianura'
      },
      {
        id: 2,
        nameDe: 'Berggebiet',
        nameFr: 'Région de montagne',
        nameIt: 'Regione di montagna'
      },
      {
        id: 3,
        nameDe: 'Sömmerungsgebiet',
        nameFr: 'Zone intermédiaire',
        nameIt: "Regione d'estivazione"
      },
      {
        id: 99,
        nameDe: 'Nicht zugeteilt',
        nameFr: 'Non attribué',
        nameIt: 'Non classificato'
      }
    ]
  })
})
